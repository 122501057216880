<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
            <h4 class="card-title">{{  $t('sidebar.wareManagementSystem.warehouseDashboard') }}</h4>
        </template>
      <template v-slot:body>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
            <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('globalTrans.organization')"
                label-for="org_id"
            >
              <b-form-select
                  plain
                  v-model="search.org_id"
                  :options="organizationtList"
                  id="org_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
            <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('warehouse_report.division')"
                label-for="division_id"
            >
              <v-select name="division_id"
                    v-model="search.division_id"
                    label="text"
                    :options= divisionList
                    :reduce="item => item.value"
                    :placeholder="$t('globalTrans.select')"
                    />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
            <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('warehouse_config.region')"
                label-for="region_id"
            >
              <v-select name="region_id"
                v-model="search.region_id"
                label="text"
                :options= regionList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
            <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('warehouse_report.district')"
                label-for="district_id"
            >
             <v-select name="district_id"
            v-model="search.district_id"
            label="text"
            :options= districtList
            :reduce="item => item.value"
            :placeholder="$t('globalTrans.select')"
            />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
            <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('warehouse_report.upazilla')"
                label-for="upazilla_id"
            >
              <v-select name="upazilla_id"
                v-model="search.upazilla_id"
                label="text"
                :options= upazilaList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
            <b-form-group
                class="row"
                label-cols-sm="4"
                :label="$t('globalTrans.union')"
                label-for="union_id"
            >
              <v-select name="union_id"
                v-model="search.union_id"
                label="text"
                :options= unionList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4" v-if="!authUser.warehouse_id">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="commodity_group_id"
                :label="$t('warehouse_config.warehouse_type')"
            >
            <v-select name="warehouse_type_id"
                v-model="search.warehouse_type_id"
                label="text"
                :options= warehouseTypeList
                :reduce="item => item.value"
                :placeholder="$t('globalTrans.select')"
                />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="warehouse_id"
                :label="$t('warehouse_information.warehouse_name')"
            >
              <v-select name="warehouse_id"
                v-model="search.warehouse_id"
                label="text"
                :options= warehouseList
                :reduce="item => item.value"
                v-if="!authUser.warehouse_id"
                :placeholder="$t('globalTrans.select')"
                />
                <b-form-select
                  plain
                  v-model="search.warehouse_id"
                  :options="warehouseList"
                  id="warehouse_id"
                  v-else
              />
            </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <b-button type="button" variant="primary float-right" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row mt-5>
                <b-col md="12">
                  <!-- Apex Chart -->
                  <iq-card class="pt-2">
                    <template v-slot:body>
                      <b-row>
                        <b-col lg="12">
                          <b-overlay class="border shadow p-2" :show="loading">
                            <h5 class="text-center">{{ $t('sidebar.wareManagementSystem.warehouseDashboard')}}</h5>
                            <apexchart type="bar" height="350" :options="chartOptions" :series="series()"></apexchart>
                          </b-overlay>
                        </b-col>
                      </b-row>
                    </template>
                  </iq-card>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { warehouseServiceBaseUrl } from '@/config/api_config'
import { warehouseDashboardApi } from '../../api/routes'

export default {
  data () {
    return {
      showData: false,
      showHeading: false,
      search: {
        org_id: 13,
        division_id: null,
        region_id: null,
        district_id: null,
        upazilla_id: null,
        union_id: null,
        warehouse_id: null,
        warehouse_type_id: null
      },
      organization: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      warehouseList: [],
      tempList: [],
      warehouseServiceBaseUrl: warehouseServiceBaseUrl,
      seriesData: [0, 0, 0, 0, 0, 0, 0],
      loading: false
    }
  },
  computed: {
    isWareHouseUser () {
      return this.authUser.warehouse_id
    },
    warehouseTypeList: function () {
      return this.$store.state.warehouse.warehouseTypeList.filter(item => item.status === 0)
    },
    organizationtList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    fiscalList: function () {
      return this.$store.state.warehouse.fiscalYearList
    },
    regionList: function () {
        let regionList = this.$store.state.warehouse.regionList.filter(item => item.status === 0)
        if (this.isWareHouseUser) {
          regionList = regionList.filter(item => item.value === this.search.region_id)
        }
        return regionList
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    currentLocale () {
      return this.$i18n.locale
    },
    authUser () {
      return this.$store.state.Auth.authUser
    },
    chartOptions () {
      return {
        chart: {
          height: 450,
          type: 'bar'
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: 'top' // top, center, bottom
            }
          }
        },
        colors: ['#0070C0'],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#0070C0']
          }
        },
        xaxis: {
          categories: [this.$t('warehouse_service.totalSpace'), this.$t('warehouse_service.actualSpace'), this.$t('warehouse_service.freeSpace'), this.$t('warehouse_service.filledSpace'), this.$t('warehouse_warehouse_management.totalRequest'), this.$t('warehouse_warehouse_management.duesAmount'), this.$t('warehouse_warehouse_management.collectionAmount')],
          position: 'bottom',
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: false
          },
          tooltip: {
            enabled: true
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val
            }
          }
        }
      }
    }
  },
  watch: {
    'search.region_id': function (newVal, oldVal) {
      if (!this.isWareHouseUser) {
            this.districtList = this.getRegionDistrictList(newVal)
        }
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
    this.unionList = this.getUnionList(newVal)
        if (!this.isWareHouseUser) {
        this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
        }
    },
    'search.union_id': function (newVal, oldVal) {
        if (!this.isWareHouseUser) {
          this.warehouseList = this.getWarehouseName(newVal, 'UP')
        }
    },
    'search.warehouse_type_id': function (newVal, oldVal) {
        if (!this.isWareHouseUser) {
          this.warehouseList = this.getWarehouseName(newVal, 'WT')
        }
    }
  },
  mounted () {
    core.index()
    if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
    }
    if (this.isWareHouseUser) {
        this.search.division_id = this.authUser.office_detail.division_id
        this.search.region_id = this.$store.state.warehouse.regionDetailList.find(item => item.district_id === this.authUser.office_detail.district_id).region_id
        this.search.district_id = this.authUser.office_detail.district_id
        this.districtList = this.$store.state.commonObj.districtList.filter(doc => doc.value === this.authUser.office_detail.district_id)
        this.search.upazilla_id = this.authUser.office_detail.upazilla_id ?? 0
        this.upazilaList = this.$store.state.commonObj.upazilaList.filter(doc => doc.value === this.authUser.office_detail.upazilla_id)
        this.search.union_id = this.authUser.office_detail.union_id ?? 0
         this.unionList = this.$store.state.commonObj.unionList.filter(doc => doc.value === this.authUser.office_detail.union_id)
        const warehouse = this.$store.state.warehouse.warehouseInfoList.find(doc => doc.office_id === this.authUser.office_detail.id)
        this.search.warehouse_id = warehouse.value
        this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(doc => doc.office_id === this.authUser.office_detail.id)
        this.search.warehouse_type_id = warehouse.warehouse_type_id
    }
  },
  methods: {
    series () {
      return [
          {
          data: this.seriesData
        }
      ]
    },
    searchData () {
      if (this.search.org_id !== 0 || this.search.division_id !== 0 || this.search.district_id !== 0 || this.search.upazilla_id !== 0) {
        this.loadData()
        this.showData = true
        this.showHeading = true
      }
    },
    async loadData () {
      this.loading = true
      await RestApi.getData(warehouseServiceBaseUrl, warehouseDashboardApi, this.search).then(response => {
        if (response.success) {
          if (response.data.length) {
            setTimeout(() => {
              this.seriesData = response.data
            }, 1000)
          } else {
            this.seriesData = [0, 0, 0, 0, 0, 0, 0]
          }
        }
      })
      this.loading = false
    },
    getRegionDistrictList (regionId = null) {
      RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
          const dataObjectTemp = response.filter(document => document.region_id === regionId)
          const dataObject = dataObjectTemp.filter(item => item.status === 0)
          this.districtList = dataObject.map(obj => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
            } else {
              return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
            }
          })
        }
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)

      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }

      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
         this.tempList = list
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
         this.tempList = list
      }

      if (Id && type === 'WT') {
        const filterData = this.tempList
        list = filterData.filter(warehouse => warehouse.warehouse_type_id === Id)
      }

      return list
    },
    getUnionList (upazillaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazillaId) {
        return unionList.filter(union => union.upazilla_id === upazillaId)
      }
      return unionList
    }
  }
}
</script>
<style scoped>
.container {
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
}
.report-name {
  text-align: center;
}
.org-name {
  text-align: center;
}
.org-address {
  text-align: center;
}
.main-title {
  padding: 10px;
}
.project-name {
  text-align: center;
  font-weight: bold;
}
</style>
