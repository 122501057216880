export const testListApi = '/list'
export const testStoreApi = '/store'
export const testUpdateApi = '/update'
export const testToggleStatusApi = '/toggle-status'
export const warehouseDashboardApi = '/warehouse-management/dashboard'
export const spaceManagementReportApi = '/warehouse-management/space-management-report'
export const warehouseReportApi = '/warehouse-management/warehouse-report'
// warehouse management api
export const reportHeadingList = '/master-warehouse-report-heading/detail'
export const documentCategoryupdate = ''
export const documentCategorystore = ''
